import * as React from "react";
import "../blog/style.scss";
import AmazeePage from "../components/layout";
import AmazeeIoMetadata from "../components/metadata";
import ResuableBody from "../components/reusable";
import { PageTitle } from "../components/title/title";
import Helmet from "react-helmet";
import { PrismicImage, PrismicRichText } from "../utils/queries/types";
import usePreviewData from "../utils/usePreviewData";
import {
  PageHeaderCustomV2WhiteWide,
  PageHeaderh2,
} from "../components/page-header/page-header";
import "./person.scss";
import "./tech-landing-page.scss";

const TechnologyLandingPage: React.FC<{
  location: Location;
  pageContext: {
    page_title: string;
    body: any;
    page_meta_thumbnail?: PrismicImage;
    page_meta_description?: string;
    meeting_cta_title?: string;
    meeting_cta_image?: PrismicImage;
    meeting_cta_description?: PrismicRichText;
    hide_case_study_teaser: boolean;
  };
}> = ({ pageContext, location }) => {
  const liveData = usePreviewData(
    pageContext,
    "prismicTechnologyLandingPage",
    true
  );
  const {
    page_title,
    body,
    meta_title,
    page_meta_thumbnail,
    page_meta_description,
    meeting_cta_title,
    meeting_cta_image,
    meeting_cta_description,
    hide_case_study_teaser,
    canonical_url,
  } = liveData;

  const title = meta_title ? meta_title : page_title;

  return (
    <AmazeePage location={location} id="technology_landing_page">
      <Helmet>
        {canonical_url && <link rel="canonical" href={canonical_url} />}
      </Helmet>
      <PageTitle title={title} />
      <AmazeeIoMetadata
        title={title}
        page_meta_thumbnail={page_meta_thumbnail}
        page_meta_description={page_meta_description}
        location={location}
      />
      <ResuableBody body={body} />
      <PageHeaderh2
        component={PageHeaderCustomV2WhiteWide}
        header={meeting_cta_title}
        image={meeting_cta_image}
        subheader={meeting_cta_description}
        withSiteName={false}
        hasSectionDivider={false}
      />

      <section className="no-padding">
        {page_title == "AI Hosting" && (
          <iframe
            title={"hubspot"}
            style={{
              width: "100%",
              height: "719px",
            }}
            src={
              "https://go.amazee.io/meetings/martin-schloegl/session-ai-hosting-by-amazeeio?embed=true"
            }
          />
        )}

        {page_title == "You do the Drupal. We do the Ops." && (
          <iframe
            title={"hubspot"}
            style={{
              width: "100%",
              height: "719px",
            }}
            src={
              "https://go.amazee.io/meetings/martin-schloegl/live-demo?embed=true"
            }
          />
        )}

        {page_title == "You do the Laravel. We do the Ops." && (
          <iframe
            title={"hubspot"}
            style={{
              width: "100%",
              height: "719px",
            }}
            src={
              "https://go.amazee.io/meetings/martin-schloegl/live-demo?embed=true"
            }
          />
        )}
      </section>

      {!hide_case_study_teaser || hide_case_study_teaser === false ? (
        <section id="resource-section">
          <div className="container">
            <h2 className="avenir">Read our Case Study</h2>

            <div className="resource-inner-wrap">
              <div className="py-12 resource-card-link feature block col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="flex resource-card feature cursor-pointer">
                      <div className="w-full h-full">
                        <img
                          src="https://images.prismic.io/amazeeio/45e07fdf-8134-44e7-8f89-ce6df3396814_Taoti-Creative-Case-Study.jpg?auto=compress,format"
                          alt="Case Study - Breaking free from limitations to provide more for customers: How Taoti Creative did it"
                          className="resource-image w-full h-full object-cover object-center"
                          loading="lazy"
                        />
                      </div>
                      <div className="w-full content h-full bg-white">
                        <div className="content-title text-left">
                          <h3>Taoti Creative Case Study</h3>
                          <h4>
                            Breaking free from limitations to provide more for
                            customers: How Taoti Creative did it
                          </h4>
                          <p>
                            <a
                              href="/case-study/how-taoti-creative-did-it"
                              target="_blank"
                              rel="noopener"
                            >
                              Read the Case Study
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </AmazeePage>
  );
};

export default TechnologyLandingPage;
